import API from "@/helpers/Api";
import store from "@/store";
import User from "@/entity/User";

export default class ContribuableRepository extends API {

    user = new User();



    orderByOptions= [
        { text: 'id', value: 'id', name: 'contrib.id' },
        { text: 'Nom', value: 'nom', name: 'contrib.nom' },
        { text: 'Nombre de betail', value: 'nom', name: 'contrib.nbreBetail' },
        { text: 'Collectivite', value: 'collectivite', name: 'ent.id' },
        { text: 'Emplacement', value: 'nom', name: 'ram.id' },
        { text: 'Telephone', value: 'raisonSociale', name: 'contrib.telephone' },
        { text: 'Raison sociale', value: 'raisonSociale', name: 'contrib.raisonSociale' },
        { text: 'Numero Klispay', value: 'numKlis', name: 'contrib.numKlis' },
        { text: 'Date d\'enrollement', value: 'createdAt', name: 'contrib.createdAt' },
        { text: 'statut', value: 'statut', name: 'contrib.statut' },
        { text: 'enabled', value: 'enabled', name: 'contrib.enabled' },
        { text: 'Agent', value: 'user', name: 'user.id' },
    ];

    create(data){
        const contribuables = this.post('/v2/contribuables', data)
        return contribuables;
    }
    correct(id, user){
        const contribuables = this.findOne('/v2/contribuables/'+id+'/'+user+'/correct')
        return contribuables;
    }
    edit(data, id){
        const contribuables = this.patch('/v2/contribuables/'+id, data)
        return contribuables;
    }

    validate(id, user){
        const contribuables = this.findOne('/v2/contribuables/'+id+'/validate/'+user)
        return contribuables;
    }
    find(id){
        const contribuables = this.findOne('/v2/contribuables/'+id)
        return contribuables;
    }
    supprimer(id){
        const contribuables = this.delete('/v2/contribuables/'+id)
        return contribuables;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user
       // fields.push({colonne: 'contrib.nbreBetail', operator: 'is not null', value:'' })

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const contribuables = this.listingContrib('/v2/contribuables', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return contribuables;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const contribuables = this.listingContrib('/v2/contribuables?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(contribuables);
                return contribuables;
            }
            if (this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const contribuables = this.listingContrib('/v2/contribuables?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(contribuables);
                return contribuables;
            }
        }
    }
    searchContrib(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const contribuables = this.listing('/v2/contribuables', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return contribuables;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })

                const contribuables = this.listing('/v2/contribuables?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(contribuables);
                return contribuables;
            }
            if (this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const contribuables = this.listing('/v2/contribuables?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(contribuables);
                return contribuables;
            }
        }
    }


}