<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0" v-if="selectedEntite != null">{{selectedEntite.nom}} > Tous les contribuables</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Configurations</router-link></li>
            <li class="breadcrumb-item active">contribuable</li>
          </ol>
        </div>
        <div class="page-title-right">
          <div>
            <div style="display: inline">
              <button v-if="checkDate('day')"   @click="filterByDay" class="btn btn-primary ">
                Aujourd'hui
              </button>
              <button v-else   @click="filterByDay" class="btn btn-soft-secondary ">
                Aujourd'hui
              </button>
              <button  v-if="checkDate('week')"   @click="filterByWeek" class="btn btn-primary ">
                Cette semaine
              </button>
              <button  v-else   @click="filterByWeek" class="btn btn-soft-secondary ">
                Cette semaine
              </button>
              <button v-if="checkDate('month')"  @click="filterByMonth" class="btn btn-primary ">
                Ce mois
              </button>
              <button v-else  @click="filterByMonth" class="btn btn-soft-secondary ">
                Ce mois
              </button>
              <button v-if="checkDate('year')"  @click="filterByYear" class="btn btn-primary ">
                Cette année
              </button>
              <button v-else  @click="filterByYear" class="btn btn-soft-secondary ">
                Cette année
              </button>
              <div  class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical align-middle"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                  <li><button class="dropdown-item" @click="filterByYesterday">Hier</button></li>
                  <li><button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button></li>
                  <li><button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button></li>
                  <li><Flatpickr
                      @change="onDateChange"
                      v-model="daterange"
                      :config="config"
                      class="form-control "
                      placeholder="Select date"/></li>
                </ul>
              </div>
            </div>


          </div>

        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="row">
          <div class="col-md-6">
            <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
          </div>
          <div class="col-md-6">
            <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
          </div>

        </div>

        <div class="row mt-2">
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectedSecteur($event)" @change="changeSecteur($event)"  />
          </div>
        </div>
        <div class="row mt-3">
          <form @submit.prevent="handleSearch">
            <div class="card">
              <div class="card-body">
                <table class="table table-sm table-bordered">
                  <thead>
                  <th>Champ</th>
                  <th>Operation</th>
                  <th>Valeur</th>
                  </thead>
                  <tbody>
                  <tr v-for="(field, index) in fields" :key="index">
                    <td>
                      <select  v-model="field.colonne" class="form-select form-select-sm " style="width: 100%;">
                        <option v-bind:key="option.name" v-bind:value="option.name" v-for="option in repository.orderByOptions" >
                          {{ option.text }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select v-model="field.operator" class="form-select form-select-sm">
                        <option v-bind:key="option.name" v-for="option in repository.operatorOptions" v-bind:value="option.value">{{ option.text }}</option>
                      </select>
                    </td>
                    <td v-if="field.colonne === 'contrib.statut' && field.operator==='eq' ">
                      <select v-model="field.value" class="form-select form-select-sm">
                        <option v-for="statut in repository.statutOptions" v-bind:value="statut" :key="statut">{{ statut }}</option>

                      </select>
                    </td>
                    <td v-else-if="field.colonne === 'contrib.statut' && field.operator==='in' ">
                      <Select2MultipleControl @change="changeStatut($event, index)"  v-model="field.value" :options="repository.statutOptions"  class="form-select form-select-sm"></Select2MultipleControl>
                    </td>
                    <td v-else-if="field.colonne === 'ram.id' && field.operator==='eq' ">
                      <Select2 @change="changeStatut($event, index)"  v-model="field.value" :options="listRamifications.map(item => ({id: item.id, text: item.nom+' => ' + (item.parent != null)?item.parent.nom:''}))"  class="form-select form-select-sm"></Select2>
                    </td>
                    <td v-else-if="field.colonne === 'ram.id' && field.operator==='in' ">
                      <Select2MultipleControl @change="changeStatut($event, index)"  v-model="field.value" :options="ramifications"  class="form-select form-select-sm"></Select2MultipleControl>

                    </td>
                    <td v-else-if="field.colonne === 'contrib.createdAt' && field.operator==='eq' ">
                      <Flatpickr
                          v-model="field.value"
                          :config="dateConfig"
                          class="form-control form-select-sm "
                          placeholder="Select date"/>
                    </td>
                    <td v-else-if="field.colonne === 'contrib.createdAt' && field.operator==='like' ">
                      <Flatpickr
                          v-model="field.value"
                          :config="dateConfig"
                          class="form-control form-select-sm"
                          placeholder="Select date"/>
                    </td>
                    <td v-else-if="field.colonne === 'contrib.createdAt' && field.operator==='dateBetween' ">
                      <Flatpickr
                          v-model="field.x"
                          :config="dateConfig"
                          class="form-control form-select-sm"
                          placeholder="Select date"/>
                      <Flatpickr
                          v-model="field.y"
                          :config="dateConfig"
                          class="form-control form-select-sm"
                          placeholder="Select date"/>
                    </td>
                    <td v-else-if="field.colonne === 'ent.id' && field.operator==='eq' ">
                      <Select2  v-model="field.value" :options="collectivites"  class="form-select form-select-sm"></Select2>

                    </td>
                    <td v-else-if="field.colonne === 'ent.id' && field.operator==='in' ">
                      <Select2MultipleControl  @change="changeCollectivite($event, index)"   v-model="field.value" :options="collectivites"  class="form-select form-select-sm"></Select2MultipleControl>

                    </td>
                    <td v-else-if="field.colonne === 'user.id' && field.operator==='in' ">
                      <Select2MultipleControl  @change="changeCollectivite($event, index)"   v-model="field.value" :options="users"  class="form-select form-select-sm"></Select2MultipleControl>

                    </td>
                    <td v-else-if="field.operator==='between' ">
                      <input v-model= "field.x" type="text" class="form-control form-control-sm">

                      <input v-model= "field.y" type="text" class="form-control form-control-sm">

                    </td>
                    <td v-else>
                      <input v-model= "field.value" type="text" class="form-control form-control-sm">
                    </td>
                    <td>
                      <a @click="addFields" class="btn btn-sm btn-success"><i class="ri-add-line"/> </a>
                      <a  @click="removeField(index)" class="btn btn-sm btn-danger"><i class="ri-delete-bin-2-line"/> </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class=" text-end">
                  <button type="submit" class="btn btn-success float-right">Rechercher</button>
                </div>
              </div>

            </div>
          </form>
        </div>



        <div class="row">
          <div class="card card-animate mt-3">

            <div class="card-body">
              <div class="row right p-2">
                <div class="col-md-2 mt-4">
                  <div v-if="['Super Admin', 'Admin NTA'].includes(user.groupe.nom)" class="btn-group " role="group">
                    <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle show" data-bs-toggle="dropdown" aria-expanded="true">
                      Action
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" data-popper-placement="top-start" data-popper-reference-hidden="" data-popper-escaped="" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(0px, -40px);">
                      <li><a  @click="bulkValidate()" class="dropdown-item" href="#">Valider</a></li>
                      <li><a  @click="bulkChangeRamification()" class="dropdown-item" href="#">Changer de secteur</a></li>
                    </ul>
                  </div>
                </div>



              </div>


              <div class="row">
                <div class="table-responsive">
                  <div class="col-sm-auto text-start">
                    <div>
                      <button @click="exportToPDF" type="button" class="btn btn-success add-btn" data-bs-toggle="modal" id="create-btn" data-bs-target="#showModal"><i class="ri-file-pdf-fill align-bottom me-1"></i> PDF</button>
                      &nbsp;<label>Afficher:</label>&nbsp;
                      <span v-for="nbre in repository.itemsPerPageOptions" :key="nbre">
                        <a v-if="itemsPerPage.toString()===nbre.text.toString()" style="cursor: pointer; text-decoration: underline" @click="handleItemPerPage(nbre.text)">{{nbre.text}}</a>&nbsp;
                        <a v-else style="cursor: pointer;" @click="handleItemPerPage(nbre.text)">{{nbre.text}}</a>&nbsp;
                      </span>
                    </div>
                  </div>
                  <table id="element-to-convert" class="table text-start mb-0 table-bordered">
                    <thead class="table-light">
                    <tr class="text-center" >
                      <th scope="col" class="bg-success">
                        <div class="form-check">
                          <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox" value="" id="responsivetableCheck">
                          <label class="form-check-label" for="responsivetableCheck"></label>
                        </div>
                      </th>
                      <th class="bg-success" scope="col" >#</th>
                      <th class="bg-success" scope="col" colspan="2">Date</th>
                      <th class="bg-success" scope="col" colspan="5">Contribuable</th>
                      <th class="bg-success" colspan="3" scope="col">Situations fiscale</th>
                      <th class="bg-success" colspan="2" scope="col">Agent</th>
                      <th class="bg-success"></th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th @click="sortTable('contrib.createdAt')" aria-sort="ascending" style="cursor: pointer">Date <i  v-if="this.sortOrder==='desc'" class="las la-sort-alpha-down text-end text-primary"></i><i  v-else class="las la-sort-alpha-up text-end text-primary"></i> </th>
                      <th>Heure</th>
                      <th @click="sortTable('contrib.numKlis')" aria-sort="ascending" style="cursor: pointer">N° Klispay <i  v-if="this.sortOrder==='desc'" class="las la-sort-alpha-down text-end text-primary"></i><i  v-else class="las la-sort-alpha-up text-end text-primary"></i> </th>
                      <th @click="sortTable('contrib.nom')" aria-sort="ascending" style="cursor: pointer">Contribuable <i  v-if="this.sortOrder==='desc'" class="las la-sort-alpha-down text-end text-primary"></i><i  v-else class="las la-sort-alpha-up text-end text-primary"></i> </th>
                      <th @click="sortTable('ram.nom')" aria-sort="ascending" style="cursor: pointer">Emplacement <i  v-if="this.sortOrder==='desc'" class="las la-sort-alpha-down text-end text-primary"></i><i  v-else class="las la-sort-alpha-up text-end text-primary"></i> </th>
                      <th @click="sortTable('ram.telephone')" aria-sort="ascending" style="cursor: pointer">Telephone <i  v-if="this.sortOrder==='desc'" class="las la-sort-alpha-down text-end text-primary"></i><i  v-else class="las la-sort-alpha-up text-end text-primary"></i> </th>

                      <th>Nbre Betails </th>
                      <th>Prevu </th>
                      <th>Payer</th>
                      <th>Restant</th>
                      <th @click="sortTable('user.username')" aria-sort="ascending" style="cursor: pointer">Pseudo <i  v-if="this.sortOrder==='desc'" class="las la-sort-alpha-down text-end text-primary"></i><i  v-else class="las la-sort-alpha-up text-end text-primary"></i> </th>

                      <th>Nom <i class="las la-sort text-end"></i></th>
                      <th>Date <i class="las la-sort text-end"></i></th>

                      <th></th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in listContribuables" :key="item.id">
                      <td><input class="form-check-input" type="checkbox" v-model="selectedItems" v-bind:value="item.id" /></td>
                      <td>{{ index+1 }}</td>
                      <td>{{ formatDate(item.createdAt.toString(), 'DD-MM-YYYY') }}</td>
                      <td>{{ formatDate(item.createdAt.toString(), 'hh:mm:ss') }}</td>
                      <td>{{item.numKlis}}</td>
                      <td>{{item.fullName}}</td>
                      <td>{{item.ramification.nom}}</td>
                      <td>{{item.telephone}}</td>
                      <td>
                        <span  class="badge bg-success" style="font-size: 14px">{{ item.nbreBetail }}</span>
                      </td>


                      <td style="font-weight: bold;">{{ formattedNumber(item.situation.total) }}</td>
                      <td style="font-weight: bold;">{{ formattedNumber( item.situation.payer) }}</td>
                      <td style="font-weight: bold;">{{ formattedNumber((Number(item.situation.total) - Number(item.situation.payer))) }}</td>
                      <td v-if="item.createdBy != null">{{ item.createdBy.username }}</td>
                      <td v-if="item.createdBy != null">{{ item.createdBy.fullName }}</td>


                      <td>
                        <div class="dropdown">
                          <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="ri-more-2-fill"></i>
                          </a>

                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">

                          </ul>
                        </div>
                      </td>
                    </tr>

                    </tbody>
                    <tfoot class="table-light">
                    <tr class="text-center" >
                      <th scope="col" class="bg-success">
                        <div class="form-check">
                          <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox" value="" id="responsivetableCheck">
                          <label class="form-check-label" for="responsivetableCheck"></label>
                        </div>
                      </th>
                        <th class="bg-success" scope="col" >#</th>
                        <th class="bg-success" scope="col" colspan="2">Date</th>
                        <th class="bg-success" scope="col" colspan="5">Contribuable</th>
                        <th class="bg-success" colspan="3" scope="col">Situations fiscale</th>
                        <th class="bg-success" colspan="2" scope="col">Agent</th>
                        <th class="bg-success"></th>
                    </tr>

                    </tfoot>
                  </table>
                  <!-- end table -->
                </div>
              </div>
              <!-- end table responsive -->
            </div>
            <div class="card-footer">
              <div class="row">

                <div class="col-sm-12 col-md-12 ">
                  <div style="display: flex; justify-content: flex-end" class="dataTables_paginate paging_simple_numbers text-end" id="scroll-horizontal_paginate">
                    <ul class="pagination">
                      <li v-if="totalPages<=1" class="paginate_button page-item previous disabled" id="scroll-horizontal_previous">
                        <button @click="goToPage((currentPage-1))"  aria-controls="scroll-horizontal" data-dt-idx="0" tabindex="0" class="page-link">Précédent</button>
                      </li>
                      <li v-else-if="totalPages>1" class="paginate_button page-item previous disabled" id="scroll-horizontal_previous">
                        <button @click="goToPage((currentPage-1))"  aria-controls="scroll-horizontal" data-dt-idx="0" tabindex="0" class="page-link">Précédent</button>
                      </li>
                      <li v-for="page in pages" :key="page" class="paginate_button page-item active">
                        <button v-if="currentPage === page"  @click="goToPage(page)"   aria-controls="scroll-horizontal" data-dt-idx="1" tabindex="0" class="page-link active">{{ page }}</button>
                        <button v-else  @click="goToPage(page)"   aria-controls="scroll-horizontal" data-dt-idx="1" tabindex="0" class="btn btn-light">{{ page }}</button>

                      </li>
                      <li v-if="totalPages<=1" class="paginate_button page-item next disabled" id="scroll-horizontal_next">
                        <button @click="goToPage((currentPage+1))" aria-controls="scroll-horizontal" data-dt-idx="3" tabindex="0" class="page-link">Next</button>
                      </li>
                      <li v-else-if="totalPages>1" class="paginate_button page-item next " id="scroll-horizontal_next">
                        <button @click="goToPage((currentPage+1))"  aria-controls="scroll-horizontal" data-dt-idx="3" tabindex="0" class="page-link">Next</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Repository from '@/repository/ContribuableRepository'
import html2pdf from "html2pdf.js"
import Select2 from 'vue3-select2-component';
import Select2MultipleControl from 'v-select2-multiple-component';
import moment from "moment";

import store from "@/store";
import RamificationRepository from "@/repository/RamificationRepository";
import UserRepository from "@/repository/UserRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import Flatpickr from "vue-flatpickr-component";
import fr from "flatpickr/dist/l10n/fr";
export default {
  name: 'listCategorieActivitePage',
  data() {
    return {
      listCollectivites: [],
      ids: [],
      listDepartements: [],
      listRamifications: [],
      listQuartiers: [],
      listZones: [],
      listSecteurs: [],
      dashboard: {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0,
        contribuableActuel: 0,
        prevuActuel: 0,
        paiementActuel:0
      },
      report: {},
      from: null,
      to: null,
      listUsers: [],
      tableau: [],
      entite: null,
      daterange: null,
      selectedAnnee: null,
      departement: null,
      quartier: null,
      zone:null,
      qt: [],
      secteur: null,
      isLoading: false,
      fullPage: true,
      user: null,
      repository: new Repository(),
      userRepo: new UserRepository(),
      orderBy: 'contrib.createdAt',
      sortOrder: 'asc',
      itemsPerPage: 100,
      selectedEntite: null,
      allSelected: false,
      selectedItems: [],
      ms: [],
      listContribuables: null,
      totalPages: 1,
      currentPage: 1,
      loading: false,
      ramificationRepo: new RamificationRepository(),
      entiteRepo: new EntiteRepository(),
      fields: [
        {}
      ]

    }
  },
  computed: {
    ramifications(){
      return this.listRamifications.map(item=>({id: item.id, text: item.nom}))
    },
    users(){
      return this.listUsers.map(item=>({id: item.id, text: item.fullName}))
    },
    collectivites(){
      return this.listCollectivites.map(item => ({id: item.id, text: item.text}));
    },
    config(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee.text +"-" +new Date().getMonth()+"-01", this.selectedAnnee.text +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }

    },
    dateConfig(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        locale: fr.fr,
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee.text +"-" +new Date().getMonth()+"-01", this.selectedAnnee.text +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }

    },

    pages() {
      let pages = Array.from({length: this.totalPages}, (_, i) => i + 1)
      let currentPage = this.currentPage
      let visiblePages = 5
      let start = currentPage - Math.floor(visiblePages / 2)
      let end = currentPage + Math.floor(visiblePages / 2)
      if (start < 1) {
        end += 1 - start
        start = 1
      }
      if (end > this.totalPages) {
        start -= end - this.totalPages
        end = this.totalPages
      }
      if (start > 1) {
        pages.splice(0, start - 1, '...')
      }
      if (end < this.totalPages) {
        pages.splice(end, this.totalPages - end, '...')
      }
      return pages.slice(start - 1, end)
    },

  },
  methods: {
    bulkChangeRamification(){
      this.$router.push({name: 'contribuableChangeSecteur', params: {ids: JSON.stringify(this.selectedItems)}})

    },
    edit(enrollement){
      this.$router.push({name: 'editContribuables', params: { id: enrollement }  })
    },
    addFields() {
      this.fields.push({ colonne: '', operator: '', value: '', x: '', y: '' });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    formattedNumber(number) {
      if (number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return 0;
    },
    refresh(){


    },
    sortTable(colonne){
      this.orderBy = colonne;
      if (this.sortOrder === 'asc')
        this.sortOrder = 'desc';
      else
        this.sortOrder = 'asc'
      this.fillTable()

    },
    formatDate(value, format){
      return moment(value).format(format)
    },
    selectEntite(val){
      this.entite = val;
      this.isLoading=true;
      //  this.selectedEntite = val;

      this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'p.id', operator: 'is null', value: ''}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listDepartements.push({id: item.id, text: item.nom});
              this.fillTable();
            })
          });
    },
    changeEntite(val){
      this.entite = val;
      this.selectedEntite = val;
    },
    changeDepartement(val){
      this.selectedDepartement = val;
    },
    async selectDepartement(val){
      this.isLoading = true;
      this.departement = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listQuartiers.push({id: item.id, text: item.nom})

            })
            this.fillTable();

          })
    },
    changeStatut(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeCollectivite(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeRamification(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeQuartier(val){
      this.selectedQuartier = val;
    },
    selectQuartier(val){
      this.isLoading=true;
      this.quartier = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})
            })
            this.fillTable()
          })


    },
    contribuables()
    {
      return this.listContribuables.filter(item => item.statut='Validé');
    },


    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        html2canvas:  { scale: 2 },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        filename: "liste-contribuables.pdf",
        jsPDF:        { unit: 'mm', format: 'a0', orientation: 'landscape' }
      });
    },
    changeZone(val){
      this.selectedZone = val;
    },
    selectZone(val){
      this.isLoading=true;
      this.zone = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})
            })
            this.fillTable()
          })

    },
    validate(enrollement){
      if(confirm("Etes-vous sûr de confirmer cet enrollement ?")){
        this.isLoading = true;
        this.repository.validate(enrollement, this.user.id)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.fillTable();
            });
        /* var contribuable = {
           id: null,
           enabled: false
         };
         contribuable.id=enrollement.contribuable.id;
         contribuable.enabled = true;
         this.contribRepo.edit(contribuable, contribuable.id);*/
        this.fillTable();
        this.isLoading = false;



      }
    },

    deleteNatureActivite(id){
      if(confirm("Etes-vous sûr de supprimer cet élément ?")){
        this.loading = true;
        this.repository.supprimer(id)
            .then(response => {
              this.fillTable();
              this.loading = false;
              console.log(response.status)
            })

      }


    },
    checkDate(type){
      if (type === 'day')
        return this.daterange === moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD") || this.daterange === moment().format("YYYY-MM-DD")
      if (type === 'week')
        return this.daterange === moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD")
      if (type === 'month')
        return this.daterange ===moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD")
      if (type === 'year')
        return this.daterange ===moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD")

    },

    bulkDelete(){
      if(confirm("Etes vous sûr de vouloir supprimer cet élément ?")){
        this.loading = true;

        this.selectedItems.forEach(element => {
          this.repository.supprimer(element);
          //console.log(element);
        })
        this.loading = false;
        this.fillTable();
      }

    },
    selectAll() {
      if (this.allSelected === false){
        this.allSelected = true;
        this.selectedItems = this.listContribuables.map(type => type.id);
        //  console.log(this.selectedItems);
      }
      else{
        this.allSelected = false;
        this.selectedItems = [];

        // console.log(this.selectedItems);

      }

      //console.log(this.allSelected)
    },
    goToPage(page){
      this.currentPage = page;
   //   this.$router.replace({ name: "listeContribuables",  query: {q1: "q1"} })
      this.fillTable();
    },

    handleItemPerPage(event){
      this.itemsPerPage = event;
      //   console.log('total de page ' + this.pages)
      // console.log(this.itemsPerPage)
      this.currentPage=1;
      this.$router.replace({ name: "listeContribuables",  query: {itemsPerPage: event, orderBy: this.orderBy} })

      this.fillTable();


    },
    onDateChange(){
      this.fillTable();
    },
    filterByDay() {
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
      this.fillTable();
     // console.log(this.daterange)
    },
    filterByYesterday() {
      this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
      this.fillTable();
      //console.log(this.daterange)
    },
    filterByLastWeek() {
      this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
      this.fillTable();
      //console.log(this.daterange)
    },
    filterByLastMonth() {
      this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      this.fillTable();
      //console.log(this.daterange)
    },
    filterByWeek() {
      this.daterange = moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD");
     // console.log(this.daterange);
      this.fillTable();
    },
    filterByMonth() {
      this.daterange = moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD");
     // console.log(this.daterange);
      this.fillTable();
    },
    filterByYear() {
      this.daterange = moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD");
      this.fillTable();
      console.log( ' au ' + moment().endOf('year'). format("YYYY-MM-DD"))
    },
    handleSearch(){
      this.fillTable();
    },
    handleOrderBy(event){
      this.$router.replace({ name: "listeContribuables",  query: {orderBy: event.target.value} })

      this.orderBy = event.target.value;
      this.fillTable();

      //console.log(this.orderBy)

    },
    handleSortOrder(event){
      this.sortOrder = event.target.value;
      // console.log(this.sortOrder)
      this.fillTable();

    },
    getDatesInRange(startDate, endDate) {
      const date = new Date(startDate.getTime());

      const dates = [];

      while (date <= endDate) {
        dates.push(moment(date).format('YYYY-MM-DD'));
        date.setDate(date.getDate() + 1);
      }

      return dates;
    },
    async fillTable(){
      this.isLoading = true;

      this.ms = [];

     // console.log(this.fields);

      this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'ent.id'), 1)
      this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'contrib.nbreBetail'), 1)

        this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
        this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange;
        this.fields.push({colonne: 'contrib.createdAt', operator: 'dateBetween', x: this.from, y:this.to})
        this.fields.push({colonne: 'contrib.nbreBetail', operator: 'is not null', value:'' })




    //  console.log('test')
      await this.repository.search(this.fields, this.orderBy, 'desc', this.itemsPerPage, this.currentPage)
          .then(response => {

            this.listContribuables = response.data;

            this.totalPages = Math.ceil(response.meta.total_records / this.itemsPerPage);

            this.isLoading = false;

          });



    },


  },
  created() {
   // this.fields.push({ colonne: 'contrib.statut', operator: 'eq', value: 'Validé' })

    this.selectedEntite = store.state.selectedCollectivite;
    //this.fields.push({colonne: 'contrib.statut', operator: 'eq', value: 'Validé'})
    this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment(). format("YYYY-MM-DD");
    this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
    this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange;
   // this.query = Object.values(this.$route.query);

       this.fields.push({colonne: 'contrib.createdAt', operator: 'dateBetween', x: this.from, y: this.to});
      this.fields.push({colonne: 'contrib.nbreBetail', operator: 'is not null', value:'' })

    console.log(this.entite)
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;
     this.userRepo.search([{colonne: '', operator: '', value: ''}], 'user.username', 'asc', 3000, 1)
        .then(response => {
          this.listUsers = response.data;
          //     this.fillTable();
        })

    this.ramificationRepo.search([{colonne: 'ram.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 3000, 1)
        .then(response => {
          this.listRamifications = response.data;
          //     this.fillTable();
        })

    this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.ids = [];
          this.ids = response.data.map(item => item.id);
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom});

          })


        })
    //this.fillTable();

  },
  components:{
    Loading,
    Select2,
    Flatpickr,
    Select2MultipleControl



  }
}
</script>
