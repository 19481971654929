import ListeTransaction from "@/components/transaction/ListeTransaction.vue";


export default [

    {
        path: '/transactions',
        name: 'listeTransactions',
        component: ListeTransaction
    }
]