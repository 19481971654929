import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
window.$ = window.jQuery = require("jquery");


import 'flatpickr/dist/flatpickr'
import 'flatpickr/dist/flatpickr.css'
import './assets/libs/jsvectormap/css/jsvectormap.min.css'
import './assets/css/bootstrap.min.css'
import VueApexCharts from 'vue3-apexcharts'
import 'bootstrap'
import './assets/libs/swiper/swiper-bundle.min.css'
import './assets/css/icons.min.css'
import './assets/css/app.min.css'
import 'vue'

import './assets/libs/jsvectormap/css/jsvectormap.min.css'
import './assets/css/bootstrap.min.css'
import 'bootstrap'
import './assets/libs/swiper/swiper-bundle.min.css'
import './assets/css/icons.min.css'
import './assets/css/app.min.css'
import VueQRCodeComponent from 'vue-qrcode-component'
import 'vue'
createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)

    .component('qr-code', VueQRCodeComponent)
    .mount('#app')
