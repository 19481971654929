import API from "@/helpers/Api";
import store from "@/store";
import User from "@/entity/User";

export default class MoyenPaiementRepository extends API {

    user = new User();


    orderByOptions= [
        { text: 'id', value: 'id', name: 'mp.id' },
        { text: 'nom', value: 'nom', name: 'mp.nom' },
        { text: 'description', value: 'description', name: 'mp.description' },
        { text: 'createdAt', value: 'createdAt', name: 'mp.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'mp.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'mp.enabled' },
    ];

    create(data){
        const moyen_paiements = this.post('/v2/moyen_paiements', data)
        return moyen_paiements;
    }
    edit(data, id){
        const moyen_paiements = this.patch('/v2/moyen_paiements/'+id, data)
        return moyen_paiements;
    }
    find(id){
        const moyen_paiements = this.findOne('/v2/moyen_paiements/'+id)
        return moyen_paiements;
    }
    supprimer(id){
        const moyen_paiements = this.delete('/v2/moyen_paiements/'+id)
        return moyen_paiements;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const moyen_paiements = this.listing('/v2/moyen_paiements', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return moyen_paiements;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const moyen_paiements = this.listing('/v2/moyen_paiements?orderBy=mp.nom&sortOrder=asc&itemperpage=30&page=1', fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(moyen_paiements);
                return moyen_paiements;
            }
            if (this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const moyen_paiements = this.listing('/v2/moyen_paiements?orderBy=mp.nom&sortOrder=asc&itemperpage=30&page=1&filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(moyen_paiements);
                return moyen_paiements;
            }
        }
    }


}