import API from "@/helpers/Api";
import store from '@/store'

export default class DashboardRepository extends API {

    selectedCollectivite = store.state.selectedCollectivite;




    orderByOptions= [
        { text: 'id', value: 'id', name: 'ca.id' },
        { text: 'nom', value: 'nom', name: 'ca.nom' },
        { text: 'description', value: 'description', name: 'ca.description' },
        { text: 'createdAt', value: 'createdAt', name: 'ca.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'ca.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'ca.enabled' },
    ];

    dashboardByEntite(entite, daterange, annee){
       // await this.getToken();
       // console.log(this.selectedCollectivite)

        if (this.selectedCollectivite === null){
            const single = this.findOne('/v2/dashboard/opmouton-byEntite?entites='+entite+'&daterange='+daterange+'&annee='+annee)
            return single;
        }else{
            const single = this.findOne('/v2/dashboard/opmouton-byEntite?entites='+this.selectedCollectivite.id+'&daterange='+daterange+'&annee='+annee)
            return single;
        }
    }
    dashboardDetailsByEntite(entite, daterange, annee){
       // await this.getToken();

        if(this.selectedCollectivite === null){
            const single = this.findOne('/v2/dashboard-details/opmouton-byEntite?entites='+entite+'&daterange='+daterange+'&annee='+annee)
            return single;
        }else{
            const single = this.findOne('/v2/dashboard-details/opmouton-byEntite?entites='+this.selectedCollectivite.id+'&daterange='+daterange+'&annee='+annee)
            return single;
        }
    }
    dashboardDetailsByRamification(ramification, daterange, annee){
       // await this.getToken();s

        const single = this.findOne('/v2/dashboard/ramification-details/'+ramification+'?daterange='+daterange+'&annee='+annee)
        return single;
    }
    dashboardByRamification(ramification, daterange, annee){
       // await this.getToken();s

        const single = this.findOne('/v2/dashboard/ramification/'+ramification+'?daterange='+daterange+'&annee='+annee)
        return single;
    }






}