<template>
    <div class="row hidden-print" >
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   />
                </div>
                <div class="col-md-4">
                    <input type="text" class="form-control" v-model="nombre"/>
                </div>
                <div class="col-md-2 text-start">
                    <button @click="generateCard" class="btn  btn-success">Generer</button>

                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="page">
            <div class="card" v-for="(card, index) in listCartes" :key="index">
                <img src="../../assets/images/card.png" alt="Image" style="width: 100%;height: 181px; border: 3px solid black;" >
                <p class="card-number">{{ card.code }}</p>
                <div>
                    <qr-code :text="card.code" size="53" class="qrcode"></qr-code>
                </div>
                <!--      <qr-code :text="formatNumber(card)" size="150" class="print-only"></qr-code>-->

            </div>
        </div>

    </div>
</template>
<script>
import Select2 from 'vue3-select2-component';
import EntiteRepository from "@/repository/EntiteRepository";

export default {
    name: 'AmateurPage',

    computed: {},
    data() {
        return {
            isPreview: false,
            numeroCarte: 1,
            listCartes: [],
            selectedEntite: null,
            entiteRepo: new EntiteRepository(),
            listCollectivites: [],
            nombre: null,
            cards: Array.from({length: 10}, (_, index) => index + 1),

        }
    },
    mounted() {
      //  this.generateCards();
        setInterval(() => {
            if (this.numeroCarte < 1000) {
                this.numeroCarte++;
            } else {
                this.numeroCarte = 1;
            }
        }, 1000);
        if (window.matchMedia) {
            const mediaQueryList = window.matchMedia('print');
            this.isPreview = mediaQueryList.matches;
            mediaQueryList.addListener((mql) => {
                this.isPreview = mql.matches;
            });
        }

    },
    methods: {
        formatNumber(card) {
            return String(card).padStart(4, '0');
        },
        generateCard(){
            if (this.selectedEntite == null){
                alert('Veuillez selectionner une entité')
            }
            if (this.nombre == null){
                alert('Veuillez saisir le nombre de carte')
            }
            if (this.nombre != null && this.selectedEntite != null){

                this.entiteRepo.findOne("/v2/cartes/bulk-creation?entite="+this.selectedEntite + "&nombre="+ this.nombre)
                    .then(response => {
                        this.listCartes = response;
                    });

            }
        }
    },
    created() {
        this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
            .then(response => {

                response.data.forEach(item => {
                    this.listCollectivites.push({id: item.id, text: item.nom});

                })

            })
    },

    components: {
        Select2
    }

}
</script>
<style>
@media print {
    .hidden-print {
        display: none !important;
    }
}
body {
    margin: 0;
    padding: 0px;
}

.page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    grid-row-gap: 5px;
}

.card-number {
    position: absolute;
    top: 19%;
    left: 62%;
    transform: translate(-50%, -50%);
    font-size: 30px;
}

@media print {
    .card-number {
        position: absolute;
        top: 19.7%;
        left: 68%;
        transform: translate(-50%, -50%);
        font-size: 11px;
    }
}


@media print {
    .qrcode {
        position: absolute;
        top: 60%;
        left: 74%;
    }
}

@media screen  {
    .qrcode {
        position: absolute;
        top: 82%;
        left: 77%;
    }
}


</style>


