import CarteProfessionnel from "@/components/impressions/CarteProfessionnel.vue";


export default [

    {
        path: '/impressions/carte-professionnel',
        name: 'impressionCarteProfessionel',
        component: CarteProfessionnel
    }
]