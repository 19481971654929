import { createRouter, createWebHistory } from 'vue-router'
import auth from "@/router/auth";
import dashboard from "@/router/dashboard";
import Layout from "@/components/Layout";
import impression from "@/router/impression";
import transaction from "@/router/transaction";
import contribuable from "@/router/contribuable";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    children: [
        ...dashboard,
        ...auth,
        ...impression,
        ...transaction,
        ...contribuable
    ],
    beforeEnter: (to, from, next) => {
      // Vérifiez si l'utilisateur est authentifié et autorisez l'accès à la route
      // sinon redirigez vers la page de connexion
      let isAuthenticated = 0;
      if(localStorage.getItem('user') != null)
        isAuthenticated = 1;

      if (isAuthenticated) {
        next();
      } else {
        next('/login');
      }

    },
  },
  ...auth,

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes
})

export default router
