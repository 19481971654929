<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
          <span v-if="entite !=null">{{ entite.text }}</span>
          <span v-if="departement !=null"> > {{ departement.text }}</span>
          <span v-if="quartier !=null"> > {{ quartier.text }}</span>
          <span v-if="zone !=null"> > {{ zone.text }}</span>
        </h4>
        <div class="page-title-right">
          <div>
            <div style="display: inline">
              <button v-if="checkDate('day')"   @click="filterByDay" class="btn btn-primary ">
                Aujourd'hui
              </button>
              <button v-else   @click="filterByDay" class="btn btn-soft-secondary ">
                Aujourd'hui
              </button>
              <button  v-if="checkDate('week')"   @click="filterByWeek" class="btn btn-primary ">
                Cette semaine
              </button>
              <button  v-else   @click="filterByWeek" class="btn btn-soft-secondary ">
                Cette semaine
              </button>
              <button v-if="checkDate('month')"  @click="filterByMonth" class="btn btn-primary ">
                Ce mois
              </button>
              <button v-else  @click="filterByMonth" class="btn btn-soft-secondary ">
                Ce mois
              </button>
              <button v-if="checkDate('year')"  @click="filterByYear" class="btn btn-primary ">
                Cette année
              </button>
              <button v-else  @click="filterByYear" class="btn btn-soft-secondary ">
                Cette année
              </button>
              <div  class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical align-middle"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                  <li><button class="dropdown-item" @click="filterByYesterday">Hier</button></li>
                  <li><button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button></li>
                  <li><button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button></li>
                  <li><Flatpickr
                      @change="onDateChange"
                      v-model="daterange"
                      :config="config"
                      class="form-control "
                      placeholder="Select date"/></li>
                </ul>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
    </div>
    <div class="col-md-6">
      <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
    </div>

  </div>
  <div class="row mt-2">
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectSecteur($event)" @change="changeSecteur($event)"  />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-xl-12">
      <div class="card crm-widget">
        <div class="card-body p-0">
          <div class="row row-cols-xxl-4">
            <div class="col text-start bg-warning">
              <div class="py-4 px-3">
                <h5 class=" text-uppercase fs-13">Betail(s) Identifié(s) </h5>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="ri-user-3-line display-6 text-white"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h2 class="mb-0"><span class="counter-value" data-target="{{ formattedNumber(dashboard.betails) }}">{{ formattedNumber(Number(dashboard.betails)) }}</span></h2>
                  </div>
                </div>
              </div>
            </div><!-- end col -->
            <div class="col text-start bg-info">
              <div class="py-4 px-3 text-white">
                <h5 class="text-uppercase fs-13 text-white">Assiète </h5>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="ri-money-dollar-box-line display-6 text-white"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h2 class="mb-0"><span class="counter-value text-white" data-target="{{dashboard.prevu}}">{{ formattedNumber(dashboard.prevu) }}</span></h2>
                  </div>
                </div>
              </div>
            </div><!-- end col -->
            <div class="col text-start bg-success">
              <div class="py-4 px-3">
                <h5 class="text-uppercase fs-13 text-white">Montant payé <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="ri-money-dollar-box-line display-6 text-white"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h2 class="mb-0"><span class="counter-value text-white" data-target="197">{{ formattedNumber(Number(dashboard.paiement)) }}</span></h2>
                  </div>
                </div>
              </div>
            </div><!-- end col -->
            <div class="col text-start bg-danger">
              <div class="py-4 px-3">
                <h5 class=" text-uppercase fs-13 text-white">Montant Restant </h5>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="ri-money-dollar-box-line display-6 text-white"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h2 class="mb-0"><span class="counter-value text-white" data-target="{{dashboard.prevu-dashboard.paiement}}">{{ formattedNumber(dashboard.prevu-dashboard.paiement )}}</span></h2>
                  </div>
                </div>
              </div>
            </div><!-- end col -->
          </div><!-- end row -->
        </div><!-- end card body -->
      </div><!-- end card -->
    </div><!-- end col -->
  </div>
    <div class="row mt-3">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title text-start">Total paiement par collectivité</h3>
                </div>
                <div class="card-body">
                    <apexchart height="400" type="donut" :options="options()" :series="series()"></apexchart>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title text-start">Nombre de contribuables par catégorie</h3>
                </div>
                <div class="card-body">
                    <apexchart height="400" type="donut" :options="options()" :series="series()"></apexchart>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <table class="table table-bordered text-start text-center">
                        <thead>
                        <tr class=" ">
                            <th class="bg-primary text-white text-center">Emplacement</th>
                            <th class="bg-warning">Betail(s) identifié(s)</th>
                            <th class="bg-warning">Contribuable(s)</th>
                            <th class="bg-info" >Assiète</th>
                            <th class="bg-success">Payé</th>
                            <th class="bg-danger">Montant <br> Restant</th>

                        </tr>
                        </thead>
                        <tbody >
                        <tr v-for="item in tableau" :key="item">
                            <td style="font-weight: bold" class="text-center bold bg-primary text-white ">{{item.collectivite}}</td>
                            <td style="font-weight: bold"  class="text-end bg-warning"> {{ formattedNumber(item.betails) }}</td>
                            <td style="font-weight: bold"  class="text-end bg-warning">{{formattedNumber(item.contribuables)}}</td>
                            <!--<td>0</td>-->
                            <td style="font-weight: bold"  class="text-end bg-info">{{formattedNumber(item.prevu)}}</td>
                            <td style="font-weight: bold"  class="text-end bg-success">{{ formattedNumber(item.paiement) }}</td>
                            <td style="font-weight: bold"  class="text-end bg-danger">{{formattedNumber(item.prevu-item.paiement )}}</td>



                        </tr>
                        </tbody>
                        <tfoot class=" ">
                        <tr class="">
                            <th class="bg-primary text-white">TOTAL</th>
                            <th class="text-end bg-warning">{{formattedNumber(totalBetail)}}</th>
                            <th class="bg-warning">{{formattedNumber(totalContribuable)}}</th>
                            <th class="text-end bg-info">{{formattedNumber(totalPrevu)}}</th>

                            <th class="text-end bg-success">{{formattedNumber(totalPaiement)}}</th>
                            <th class="text-end bg-danger">{{formattedNumber(totalRestant)}}</th>


                        </tr>
                        </tfoot>
                    </table>

                </div>
            </div>
        </div>

    </div>




</template>
<script>
import Select2 from 'vue3-select2-component';
import fr from "flatpickr/dist/l10n/fr";
import Flatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import EntiteRepository from "@/repository/EntiteRepository";
import RamificationRepository from "@/repository/RamificationRepository";
import DashboardRepository from "@/repository/DashboardRepository";
import Loading from "vue-loading-overlay";
import moment from "moment";
export default {
  name: 'DashboardPage',
  computed: {

    config(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee.text +"-" +new Date().getMonth()+"-01", this.selectedAnnee.text +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }


    },
      totalContribuable()
      {
          let sum = 0;
          for (const item of this.tableau) {
              sum += (item != null) ? parseInt(item.contribuables) : 0;
          }
          return sum;
      },
      totalBetail()
      {
          let sum = 0;
          for (const item of this.tableau) {
              sum += (item != null) ? parseInt(item.betails) : 0;
          }
          return sum;
      },

      totalPrevu()
      {
          let sum = 0;
          for (const item of this.tableau) {
              sum += (item != null) ? Number(item.prevu) : 0;
          }
          return sum;
      },

      totalPaiement()
      {
          let sum = 0;
          for (const item of this.tableau) {
              sum += (item != null) ? Number(item.paiement) : 0;
          }
          return sum;
      },

      totalRestant()
      {
          let sum = 0;
          let totalPrevu = 0;
          let totalPaiement = 0;
          for (const item of this.tableau) {
              totalPrevu += (item != null) ? Number(item.prevu) : 0;
          }
          for (const item of this.tableau) {
              totalPaiement += (item != null) ? Number(item.paiement) : 0;
          }
          sum = totalPrevu - totalPaiement
          return sum;
      },


  },
  data(){
    return {
      isLoading: false,
      fullPage: true,
      selectedCollectivite: null,
      user: null,
      ids: [],
      dashboard: {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0,
        contribuableActuel: 0,
        prevuActuel: 0,
        paiementActuel:0
      },
      tableau: [],
      entite: null,
      from: '',
      to: '',
      agentChat: [],
      selectedAnnee: null,
      departement: null,
      quartier: null,
      zone:null,
      secteur: null,
      selectedEntite: null,
      daterange: null,
      selectedDepartement: null,
      selectedQuartier: null,
      selectedZone: null,
      selectedSecteur: null,
      ramificationRepo: new RamificationRepository(),
      dashboardRepo: new DashboardRepository(),
      entiteRepo: new EntiteRepository(),
      myValue: '',
      listCollectivites: [],
      listDepartements: [],
      listQuartiers: [],
      listZones: [],
      listSecteurs: [],

    }
  },
  methods:{
      options(){
          return {
              labels: this.tableau.map(item => item.collectivite)
          }
      },
      series(){
          return this.tableau.map(item => Number(item.paiement))
      },
    onDateChange(){
      this.refresh();
    },
    checkDate(type){
      if (type === 'day')
        return this.daterange === moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD") || this.daterange === moment().format("YYYY-MM-DD")
      if (type === 'week')
        return this.daterange === moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD")
      if (type === 'month')
        return this.daterange ===moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD")
      if (type === 'year')
        return this.daterange ===moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD")

    },
    filterByDay() {
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
      this.refresh();


    },
    filterByYesterday() {
      this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
      this.refresh();
      console.log(this.daterange)
    },
    filterByLastWeek() {
      this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
      this.refresh();
      console.log(this.daterange)
    },
    filterByLastMonth() {
      this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      this.refresh();
      console.log(this.daterange)
    },
    filterByLastYear() {
      this.daterange = moment().subtract(1, 'years').startOf('year').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'years').endOf('year').format("YYYY-MM-DD");
      this.refresh();
      console.log(this.daterange)
    },
    filterByWeek() {
      this.daterange = moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD");
      console.log(this.daterange);
      this.refresh();
    },
    filterByMonth() {
      this.daterange = moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD");
      console.log(this.daterange);
      this.refresh();
    },
    filterByYear() {
      this.daterange = moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD");
      console.log(this.daterange)
    },
    formattedNumber(number) {
      if (number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return 0;
    },
    selectEntite(val){
      this.entite = val;
      //this.isLoading=true;
      //  this.selectedEntite = val;
        this.dashboardRepo.dashboardByEntite(this.entite.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
                this.dashboard = response;
                this.isLoading = false
                console.log(this.dashboard)

            });

        this.dashboardRepo.dashboardDetailsByEntite(this.entite.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
                this.tableau = response;
                this.isLoading = false
                console.log(this.tableau)

            });

    },
    changeEntite(val){
      this.entite = val;
      this.selectedEntite = val;
       // this.refresh();

    },
    changeDepartement(val){
      this.selectedDepartement = val;
    },
    async selectDepartement(val){
      this.isLoading = true;
      this.departement = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];

    },
    changeQuartier(val){
      this.selectedQuartier = val;
    },
    selectQuartier(val){
      this.isLoading=true;
      this.quartier = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];

    },
    changeZone(val){
      this.selectedZone = val;
    },
    selectZone(val){
      this.isLoading=true;
      this.zone = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];

    },
    changeSecteur(val){
      this.selectedSecteur = val;
    },
    selectSecteur(val){
      this.isLoading=true;
      this.zone = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];

    },
      refresh(){
        this.isLoading = true;
          this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
          this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange.split('au')[1];

          if (this.entite === null && (this.departement === null && this.quartier === null && this.zone === null && this.secteur === null))
          {
              this.dashboardRepo.dashboardByEntite(this.ids, this.daterange, this.selectedAnnee.text)
                  .then(response => {
                      this.dashboard = response;
                  });
              this.dashboardRepo.dashboardDetailsByEntite(this.ids, this.daterange, this.selectedAnnee.text)
                  .then(response => {
                      this.tableau = response
                      this.isLoading=false;

                  });
          }
          if (this.entite != null && (this.departement === null && this.quartier === null && this.zone === null && this.secteur === null))
          {
              this.dashboardRepo.dashboardByEntite(this.entite.id, this.daterange, this.selectedAnnee.text)
                  .then(response => {
                      this.dashboard = response;
                  });
              this.dashboardRepo.dashboardDetailsByEntite(this.entite.id, this.daterange, this.selectedAnnee.text)
                  .then(response => {
                      this.tableau = response;
                      this.isLoading=false;
                  });
          }

      },


  },
  created() {

    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;
    this.$store.dispatch("setAnnee", JSON.parse(localStorage.getItem('selectedAnnee')));
    this.selectedAnnee = this.$store.state.selectedAnnee;
    this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment(). format("YYYY-MM-DD");
    this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
    this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange.split('au')[1];
    if(this.selectedAnnee != null) {
      this.selectedAnnee = this.$store.state.selectedAnnee;
    }
    else{
      this.selectedAnnee = {id: new Date().getFullYear(), text: new Date().getFullYear(), selected: true}
    }
      if (this.user.groupe.nom === 'Super Admin'){
          this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
              .then(response => {
                  this.ids = [];
                  this.ids = response.data.map(item => item.id);
                  response.data.forEach(item => {
                      this.listCollectivites.push({id: item.id, text: item.nom});

                  })
                  this.dashboardRepo.dashboardByEntite(this.ids, this.daterange, this.selectedAnnee.text)
                      .then(response => {
                          this.dashboard = response;
                      })
                  this.dashboardRepo.dashboardDetailsByEntite(this.ids, this.daterange, this.selectedAnnee.text)
                      .then(response => {
                          this.tableau = response;
                          this.isLoading = false
                          console.log(this.tableau)

                      });


              })

      }
      else{
          this.user.entites.forEach(item => {
              this.listCollectivites.push({id: item.id, text: item.nom});
              this.ids.push(item.id);
              this.dashboardRepo.dashboardByEntite(this.ids, this.daterange, this.selectedAnnee.text)
                  .then(response => {
                      this.dashboard = response;
                  })
              this.dashboardRepo.dashboardDetailsByEntite(this.ids, this.daterange, this.selectedAnnee.text)
                  .then(response => {
                      this.dashboard = response;
                  })
          })

      }


  },
  components: {
    Select2,
    Loading,
    Flatpickr

  }

}
</script>