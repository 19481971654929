import ListeContribuable from "@/components/contribuable/ListeContribuable.vue";


export default [

    {
        path: '/contribuables',
        name: 'ListeContribuables',
        component: ListeContribuable
    }
]